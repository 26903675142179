<template>
  <div class="coianter">
    <div class="flexrow tiecg">
      <div class="tilelft">江苏省工程建设标准编制立项申报表</div>
      <div class="tieright">
        <el-button type="primary" @click="goback">返回</el-button>
      </div>
    </div>
    <div class="table main-box">
      <el-form ref="form" :label-position="labelPosition" :model="form" label-width="150px">
        <el-row>
          <el-col :span="24">
            <div>
              <h3>基本信息</h3>
            </div>
          </el-col>

        </el-row>
        <div class="jiben">
          <el-row>
            <el-col :span="8">
              <el-form-item label="项目名称" prop="proName" :rules="{
                required: true,
                message: '请填写项目名称',
                trigger: 'blur',
              }">
                <el-input v-model="form.proName" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="标准类型" prop="biaozhunType" :rules="{
                required: true,
                message: '请选择类型',
                trigger: 'change',
              }">
                <el-radio-group v-model="form.biaozhunType">
                  <el-radio :label="1">地方标准</el-radio>
                  <el-radio :label="3">区域标准</el-radio>
                  <!-- <el-radio :label="2">标准设计</el-radio> -->
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="英文名称">
                <el-input v-model="form.USname" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制定类型" prop="zhidingType" :rules="{
                required: true,
                message: '请选择类型',
                trigger: 'change',
              }">
                <el-radio-group v-model="form.zhidingType">
                  <el-radio :label="1">制定</el-radio>
                  <el-radio :label="2">修订</el-radio>
                  <!-- <el-radio :label="3" >推荐性技术规程</el-radio> -->
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="第一主编单位" prop="firstUnit" :rules="{
                required: true,
                message: '请填写单位',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstUnit" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地址(邮编)" prop="firstAddress" :rules="{
                required: true,
                message: '请填写地址',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstAddress" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="第一主编单位负责人" prop="firstHandler" :rules="{
                required: true,
                message: '请填写第一主编单位负责人',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstHandler" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话(手机)" prop="firstTel" :rules="{
                max: 11,
                required: true,
                message: '请填写正确的联系电话',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstTel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱" prop="firstEmail" :rules="rules.email">
                <el-input v-model="form.firstEmail" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="first_unit">
            <el-col :span="8">
              <el-form-item label="第一主编单位项目负责人" prop="firstProHandler" :rules="{
                required: true,
                message: '请填写第一主编单位项目负责人',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstProHandler" style="width: 100%" @change="changeHandler"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话(手机)" prop="firstProHandlerTel" :rules="{
                max: 11,
                required: true,
                message: '请填写正确的联系电话',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstProHandlerTel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱" prop="firstProHandlerEmail" :rules="rules.email">
                <el-input v-model="form.firstProHandlerEmail" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="项目联系人" prop="firstProContact" :rules="{
                required: true,
                message: '请填写联系人',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstProContact" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话(手机)" prop="firstProContactTel" :rules="{
                max: 11,
                required: true,
                message: '请填写正确的联系电话',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstProContactTel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱" prop="firstProContactEmail" :rules="rules.email">
                <el-input v-model="form.firstProContactEmail" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="第二主编单位">
                <el-input v-model="form.secUnit" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地址(邮编)">
                <el-input v-model="form.secHandleEmail" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="第二主编单位负责人">
                <el-input v-model="form.secHandler" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话(手机)">
                <el-input v-model="form.secTel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱">
                <el-input v-model="form.secEmail" style="width: 100%" :rules="rules.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="first_unit">
              <el-form-item label="第二主编单位项目负责人" class="second_unit">
                <el-input v-model="form.secProHandler" style="width: 100%" @change="changeSecHandler"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话(手机)">
                <el-input v-model="form.secProTel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱">
                <el-input v-model="form.secProEmail" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="参编单位">
                <el-input v-model="form.join_companies" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            margin: 40px 0;
          ">
          <div style="font-size: 20px; color: #666666; font-weight: bold">
            主要起草人员
            <span style="font-size: 14px">(提示：前两位填写标准项目负责人)</span>
          </div>
          <div>
            <el-button type="primary" @click="addform">新增</el-button>
          </div>
        </div>
        <div class="order_num">序号调整</div>
        <div v-for="(item, index) in form.ren" :key="index" class="qicao" ref="ren">
          <el-row>
            <el-col :span="2">
              <el-form-item>
                <el-button type="text" @click="moveTable(index, 'up')">
                  <i class="el-icon-arrow-up" :class="{ 'arrow-disabled': index == 0 }"></i>
                </el-button>
                <el-button type="text" @click="moveTable(index, 'down')">
                  <i class="el-icon-arrow-down" :class="{ 'arrow-disabled': index == form.ren.length - 1 }"></i>
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <div class="serialNumber">{{ index + 1 }}</div>
              <el-form-item label="姓名" :prop="parter" :rules="{
                required: true,
                message: '请填写名称',
                trigger: ['blur', 'change']
              }">
                <el-input v-model="form.ren[index].parter" style="width: 100px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="所学专业" :prop="career" :rules="{
                required: true,
                message: '请填写专业',
                trigger: ['blur', 'change']
              }">
                <el-input v-model="form.ren[index].career" style="width: 110px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="现从事专业" :prop="careerNow" :rules="{
                required: true,
                message: '请填写现从事专业',
                trigger: ['blur', 'change']
              }">
                <el-input v-model="form.ren[index].careerNow" style="width: 100px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="职称/职务" :prop="job" :rules="{
                required: true,
                message: '请填写职务',
                trigger: ['blur', 'change']
              }">
                <el-input v-model="form.ren[index].job" style="width: 100px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="工作单位" :prop="work" :rules="{
                required: true,
                message: '请填写工作单位',
                trigger: ['blur', 'change']
              }">
                <el-input v-model="form.ren[index].work" style="width: 100px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="承担工作" :prop="take" :rules="{
                required: true,
                message: '请填写承担工作',
                trigger: ['blur', 'change']
              }">
                <el-input v-model="form.ren[index].take" style="width: 100px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <i class="el-icon-delete" @click="delform(index)"></i>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div style="
            margin: 40px 0;
            font-size: 20px;
            color: #666666;
            font-weight: bold;
          ">
          编制大纲
        </div>
        <div class="dagang">
          <el-row>
            <el-col :span="24">
              <el-form-item label="一、目的、意义" prop="purpose" :rules="{
                required: true,
                message: '请填写目的、意义',
                trigger: 'blur',
              }">
                <el-input type="textarea" rows="6" v-model="form.purpose" maxlength="2500"
                  @input="descInput"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="二、适用范围和 主要技术内容" prop="content" :rules="{
                required: true,
                message: '请填写适用范围和 主要技术内容',
                trigger: 'blur',
              }">
                <el-input type="textarea" rows="6" v-model="form.content" maxlength="2500"
                  @input="descInput"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="三、现有工作基础和需解决的重点问题,必要的测试验证项目" prop="problem" :rules="{
                required: true,
                message:
                  '请填写现有工作基础和需解决的重点问题,必要的测试验证项目',
                trigger: 'blur',
              }">
                <el-input type="textarea" rows="6" v-model="form.problem" maxlength="2500"
                  @input="descInput"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="四、与有关法律法规、相关标准的关系" prop="law" :rules="{
                required: true,
                message: '请填写与有关法律法规、相关标准的关系',
                trigger: 'blur',
              }">
                <el-input type="textarea" rows="6" v-model="form.law" maxlength="2500" @input="descInput"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <el-form-item label="五、经费" :rules="{
                required: true,
                message: '请填写经费组成',
                trigger: 'blur',
              }">
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="申请拨款" prop="bokuan" :rules="{
                required: true,
                message: '请填写数额',
                trigger: 'blur',
              }">
                <el-input v-model="form.bokuan" style="width: 115px">
                  <i slot="suffix" style="margin: 10px; font-style: normal">万元</i>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="自筹" prop="zichou" :rules="{
                required: true,
                message: '请填写数额',
                trigger: 'blur',
              }">
                <el-input v-model="form.zichou" style="width: 115px">
                  <i slot="suffix" style="margin: 10px; font-style: normal">万元</i>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="如无拨款是否采用自筹方式编制标准" prop="choutype" class="raisedefined">
                <el-radio-group v-model="raise_inde">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="六、社会经济效益分析" prop="xiaoyi" :rules="{
                required: true,
                message: '请填写社会经济效益分析',
                trigger: 'blur',
              }">
                <el-input type="textarea" rows="6" v-model="form.xiaoyi"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2.5">
              <el-form-item label="七、进度计划"> </el-form-item>
            </el-col>
            <div class="jindu">
              <el-col :span="4" class="kuandu">
                <el-form-item label="时间" prop="start_time1" :rules="rules.times">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.start_time1"
                    style="width: 220px" @change="selectEndTime"></el-date-picker>
                </el-form-item>
                <el-form-item label="时间" prop="start_time2" :rules="rules.times">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.start_time2"
                    style="width: 220px" @change="selectEndSec"></el-date-picker>
                </el-form-item>
                <el-form-item label="时间" prop="start_time3" :rules="rules.times">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.start_time3"
                    style="width: 220px" @change="selectEndthr"></el-date-picker>
                </el-form-item>
                <el-form-item label="时间" prop="start_time4" :rules="rules.times">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.start_time4"
                    style="width: 220px" @change="selectEndthr"></el-date-picker>
                </el-form-item>
                <el-form-item label="时间" prop="start_time5" :rules="rules.times">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.start_time5"
                    style="width: 220px"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="workcontent">
                <el-form-item label="工作内容" prop="worktext1">
                  <div class="inputDeep">
                    <el-input v-model="form.worktext1" style="width: 100%"
                      placeholder="提交编制大纲及技术条件分析,完成启动会议"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="工作内容" prop="worktext2">
                  <div class="inputDeep">
                    <el-input v-model="form.worktext2" placeholder="提交初稿(立项后8个月内提交初稿)"
                      style="width: 100%; box-shadow: none; border: none"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="工作内容" prop="worktext3">
                  <div class="inputDeep">
                    <el-input v-model="form.worktext3" style="width: 100%; box-shadow: none; border: none"
                      placeholder="提交征求意见稿(初审会后4个月内提交)"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="工作内容" prop="worktext4">
                  <div class="inputDeep">
                    <el-input v-model="form.worktext4" style="width: 100%; box-shadow: none; border: none"
                      placeholder="提交送审稿(4个月内提交)"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="工作内容" prop="worktext5">
                  <div class="inputDeep">
                    <el-input v-model="form.worktext5" placeholder="提交报批稿(送审会后3个月内提交)"
                      style="width: 100%; box-shadow: none; border: none"></el-input>
                  </div>
                </el-form-item>
              </el-col>
            </div>
          </el-row>
          <el-row class="stage">
            <el-col :span="8">
              <el-form-item label="是否同意以上时间进度" prop="timenode" class="raisedefined">
                <el-radio-group v-model="time_node">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

        </div>

      </el-form>
      <div style="font-size: 20px; font-weight: bold">
        申报表和承诺书上传
      </div>
      <div class="dagang">
        <el-row>
          <el-col :span="4">
            立项申报表
          </el-col>
          <el-col :span="20">
            <el-upload style="margin-top: 20px; margin-left: 30px" :file-list="lixiangFiles"
              :on-success="(response) => updateFilelist(response, 'lixiangFiles')"
              :on-remove="(file) => removeImg(file, 'lixiangFiles')" class="upload-demo" accept=".pdf"
              action="https://api.gbservice.cn/api/public/bupload">
              <el-button size="small" type="primary">点击上传</el-button>
              <div class="el-upload__tip" slot="tip">只能上传pdf文件，且不超过20Mb</div>
            </el-upload>
          </el-col>

          <el-col :span="4">
            项目负责人科研诚信承诺书
          </el-col>
          <el-col :span="20">
            <el-upload style="margin-top: 20px; margin-left: 30px" :file-list="renxinFiles"
              :on-success="(response) => updateFilelist(response, 'renxinFiles')"
              :on-remove="(file) => removeImg(file, 'renxinFiles')" class="upload-demo" accept=".pdf"
              action="https://api.gbservice.cn/api/public/bupload">
              <el-button size="small" type="primary">点击上传</el-button>
              <div class="el-upload__tip" slot="tip">只能上传pdf文件，且不超过20Mb</div>
            </el-upload>
          </el-col>

          <el-col :span="4">
            项目申报单位科研诚信承诺书
          </el-col>
          <el-col :span="20">
            <el-upload style="margin-top: 20px; margin-left: 30px" :file-list="danweiFiles"
              :on-success="(response) => updateFilelist(response, 'danweiFiles')"
              :on-remove="(file) => removeImg(file, 'danweiFiles')" class="upload-demo" accept=".pdf"
              action="https://api.gbservice.cn/api/public/bupload">
              <el-button size="small" type="primary">点击上传</el-button>
              <div class="el-upload__tip" slot="tip">只能上传pdf文件，且不超过20Mb</div>
            </el-upload>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="comit">
      <div class="baocun">
        <el-button type="primary" @click="saveProject()">保存</el-button>
      </div>
      <div class="baocun">
        <el-button type="info" @click="downloadapply">下载承诺书</el-button>
      </div>
      <div class="baocun">
        <el-button type="info" @click="getWord">下载申报表</el-button>
      </div>
      <div class="baocun">
        <el-button type="success" @click="tijiaoProject()">提交</el-button>
      </div>
    </div>
    <p style="color: red; font-size:14px;" align="center">
      提示：请填写立项申报表基本信息，填写后点击“保存”并且下载“申请表”和“承诺书”，申报表、承诺书盖章签字后上传至系统，最后点击“提交”。</p>

  </div>
</template>
<script>
import { getBiaozhunDetail, addBiaozhun, fileDel } from "@api/project";
export default {
  data() {
    return {
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      // 临时存储
      lixiangFiles: [],
      renxinFiles: [],
      danweiFiles: [],
      form: {
        id: "",
        proName: "",
        biaozhunType: 1,
        USname: "",
        zhidingType: 1,
        firstUnit: "",
        firstAddress: "",
        firstHandler: "",
        firstTel: "",
        firstEmail: "",
        firstProHandler: "",
        firstProHandlerTel: "",
        firstProHandlerEmail: "",
        secUnit: "",
        secTel: "",
        secEmail: "",
        secHandler: "",
        secHandleEmail: "",
        secProHandler: "",
        secProTel: "",
        secProEmail: "",
        purpose: "",
        content: "",
        problem: "",
        law: "",
        bokuan: "",
        zichou: "",
        xiaoyi: "",
        start_time1: "",
        endtime1: "",
        start_time2: "",
        endtime2: "",
        start_time3: "",
        endtime3: "",
        start_time4: "",
        endtime4: "",
        start_time5: "",
        endtime5: "",
        worktext1: "提交编制大纲及技术条件分析,完成启动会议",
        worktext2: "提交初稿(立项后8个月内提交初稿)",
        worktext3: "提交征求意见稿(初审会后4个月内提交)",
        worktext4: "提交送审稿(4个月内提交)",
        worktext5: "提交报批稿(送审会后3个月内提交)",
        ren: [
          {
            parter: "",
            career: "",
            careerNow: "",
            job: "",
            work: "",
            take: "",
          },
        ],
        token: "",
        // 增加文件列表
        files: []
      },
      raise_inde: "1",
      time_node: "1",
      // 通过保存返回的当前申请表的id
      applyid: '',
      rules: {
        "email": {
          type: 'email',
          required: true,
          message: '请填写正确的电子邮箱',
          trigger: 'blur',
        },
        "times": {
          required: true,
          message: '请填写时间',
          trigger: 'change',
        }
      }
    };
  },
  mounted() {
    this.getToken();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    downloadapply() {
      window.open(
        "https://api.gbservice.cn/files/2024/07/ef0168d4d91ec851324e002b7a5f9005.zip",
        "_blank"
      );
    },
    // 下载word版本的申请表
    getWord() {
      if (this.applyid == '') {
        this.$message({
          showClose: true,
          message: "您需要保存后才能下载申请表",
          type: "warning",
        });
      } else {
        // console.log(this.applyid);
        window.open(
          "https://api.gbservice.cn/api/standard/word?id=" + this.applyid,
          "_blank"
        );
      }
    },
    // 更新文件列表
    updateFilelist(response, listName) {
      // 更新各自的文件列表
      this[listName].push(response.data);
      // 更新合并的文件列表
      this.form.files.push(response.data);
    },

    // 移除清单
    removeImg(file, listName) {

      fileDel({ id: file.id ,token:this.token}).then(
        res => {
          if (res.code==200) {
            // 2. 移除各自文件列表中的文件
            const index = this[listName].indexOf(file);
            if (index > -1) {
              this[listName].splice(index, 1);
            }
            // 3. 移除合并文件列表中的文件
            const mergedIndex = this.form.files.indexOf(file);
            if (mergedIndex > -1) {
              this.form.files.splice(mergedIndex, 1);
            }
          } else {
            this.$message.error('删除文件失败');
          }
        }
      ).catch(error => {
        // console.error('删除文件错误:', error);
        this.$message.error('删除文件时发生错误');
      });

      // const index = this[listName].indexOf(file);
      // if (index > -1) {
      //   this[listName].splice(index, 1);
      // }
      // const mergedIndex = this.form.files.indexOf(file);
      // if (mergedIndex > -1) {
      //   this.form.files.splice(mergedIndex, 1);
      // }
    },

    goback() {
      this.$router.go(-1);
    },
    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
    },
    addform() {
      this.form.ren.push({
        parter: "",
        career: "",
        careerNow: "",
        job: "",
        work: "",
        take: "",
      });
      this.$forceUpdate()
    },
    //触发第一次结束时间
    selectEndTime() {
      this.form.start_time2 = this.getDay(243, 'eight')
    },
    //触发第二次结束时间
    selectEndSec() {
      this.form.start_time3 = this.getDay(90, 'three')
    },
    selectEndthr() {
      this.form.start_time4 = this.getDay(90, 'time_four')
    },
    selectTimeFour() {
      this.form.start_time5 = this.getDay(90, 'time_new')
    },
    selectTimeM() {
      if (this.form.start_time5 > this.form.endtime5) {
        this.$message({
          showClose: true,
          message: "开始时间不得大于结束时间",
          type: "warning",
        });
        return false
      }
    },
    //获取日期
    getDay(day, type) {
      var today = ''
      //以提交结束时间为基准
      if (type == 'eight') {
        today = new Date(this.form.start_time1);
      } else if (type == 'three') {
        today = new Date(this.form.start_time2);
      } else if (type == 'time_four') {
        today = new Date(this.form.start_time3);
      } else {
        today = new Date(this.form.start_time4);
      }
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    changeHandler() {
      //第一单位项目负责人对应起草人员第一栏姓名
      this.form.ren[0].parter = this.form.firstProHandler
    },
    changeSecHandler() {
      if (this.form.ren.length === 1) {
        this.form.ren.push({
          parter: this.form.secProHandler,
          career: "",
          careerNow: "",
          job: "",
          work: "",
          take: "",
        });
      } else if (this.form.ren.length === 2) {
        this.form.ren[1].parter = this.form.secProHandler
      }
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.form.ren = res.data.fu;
          this.form.files = res.data.files;
          // 格式化并赋值给对应的文件列表
          this.lixiangFiles = this.form.files[0] ? [this.form.files[0]] : [];
          this.renxinFiles = this.form.files[1] ? [this.form.files[1]] : [];
          this.danweiFiles = this.form.files[2] ? [this.form.files[2]] : [];

          this.tableData = res.data.fu; //主要起草人员展开无分页
          this.tableData2[0].start_time = res.data.start_time1;
          this.tableData2[0].endtime = res.data.endtime1;
          this.tableData2[1].start_time = res.data.start_time2;
          this.tableData2[1].endtime = res.data.endtime2;
          this.tableData2[2].start_time = res.data.start_time3;
          this.tableData2[2].endtime = res.data.endtime3;
          this.tableData2[3].start_time = res.data.start_time4;
          this.tableData2[3].endtime = res.data.endtime4;
          this.tableData2[4].start_time = res.data.start_time5;
          this.tableData2[4].endtime = res.data.endtime5;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //保存
    saveProject() {
      this.form.status = 1;
      this.form.token = this.token;
      this.form.is_time = Number(this.time_node);
      if (this.applyid != '') {
        this.form.id = this.applyid;
      }
      addBiaozhun(this.form).then((rec) => {
        // console.log(rec)
        this.applyid = rec.id;
        this.$message({
          showClose: true,
          message: "保存成功！",
          type: "success",
        });

      });

    },
    //提交项目
    tijiaoProject() {
      this.$confirm("此操作将提交该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let copyRen = this.form.ren;
        this.form.status = 2; //保存状态
        this.form.token = this.token;
        this.form.is_zichou = this.raise_inde;
        this.form.is_time = Number(this.time_node);
        this.$refs.form.validate((valid) => {
          if (valid) {
            addBiaozhun(this.form).then((rec) => {
              // console.log("rec", rec);
              this.$message({
                type: "success",
                message: "提交成功!",
              });
              this.$router.push({
                path: "/user_project/itemIdent",
              });
            });
          } else {
            this.form.ren = copyRen;
            this.$message({
              showClose: true,
              message: "请填写完整信息",
              type: "warning",
            });
            return false;
          }
        });
      });
    },

    delform(index) {
      if (this.form.ren.length > 1) {
        this.form.ren.splice(index, 1);
        this.$forceUpdate()
      } else {
        this.$message.error("至少要保留一条记录");
      }
    },
    // 
    moveTable(index, direction) {
      if (direction === "up" && index > 0) {
        [this.form.ren[index - 1], this.form.ren[index]] = [
          this.form.ren[index],
          this.form.ren[index - 1],
        ];
        this.$forceUpdate()
      }
      if (direction === "down" && index < this.form.ren.length - 1) {
        [this.form.ren[index], this.form.ren[index + 1]] = [
          this.form.ren[index + 1],
          this.form.ren[index],
        ];
      }
      this.$forceUpdate();
    },
    descInput() {
      if (this.form.purpose.length >= 2500) {
        this.$message({
          showClose: true,
          message: "一、目的、意义字数限制2500",
          type: "warning",
        });
      }
      if (this.form.content.length >= 2500) {
        this.$message({
          showClose: true,
          message: "二、适用范围和主要技术内容字数限制2500",
          type: "warning",
        });
      }
      if (this.form.problem.length >= 2500) {
        this.$message({
          showClose: true,
          message:
            "三、现有工作基础和需解决的重点问题,必要的测试验证项目，字数限制2500",
          type: "warning",
        });
      }
      if (this.form.law.length >= 2500) {
        this.$message({
          showClose: true,
          message: "四、与有关法律法规、相关标准的关系，字数限制2500",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style scoped>
.coianter .arrow-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
  font-size: 16px;
  font-weight: bold;
}

.coianter .el-icon-arrow-up {
  font-size: 16px;
  font-weight: bold;
}

.inputDeep>>>.el-input__inner {
  border: 0;
  font-weight: bold;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #a5a7ab;
  border-color: #a5a7ab;
  color: #fff;
}

/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}

/deep/ .el-input {
  width: 250px;
}

/deep/ .raisedefined .el-form-item__label {
  width: auto !important;
}

/deep/ .first_unit .el-form-item__label {
  white-space: nowrap;
}

/deep/ .first_unit .el-input {
  margin-left: 15px !important;
  width: 96% !important;
}

/deep/ .second_unit {
  display: flex;
}

/deep/ .second_unit .el-form-item__content {
  margin-left: 16px !important;
}

/deep/ .second_unit .el-form-item__label {
  padding-left: 18px;
  margin-left: 3px;
}

/deep/ .second_unit .el-input {
  width: 346px !important;
}
</style>
<style lang="scss" scoped>
.coianter {
  background-color: #ffffff;
  height: 90vh;
  overflow: hidden;
  overflow-y: scroll;

  // height: 100%;
  .order_num {
    width: 200px;
    padding-left: 150px;
    font-size: 14px;
  }

  .workcontent {
    margin-left: 5%;
  }
}

.coianter::-webkit-scrollbar {
  display: none;
}

.idteail {
  margin: 4px;
  width: 100%;
  //   height: 50px;
  line-height: 30px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
  }
}

.table {
  margin: 30px 50px;
}

.comit {
  padding: 10px 0;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 20px;
  }
}

.name_input {
  width: 100%;
}

.name_input>.el-form-item {
  width: 100%;
}

.name_input>.el-form-item .el-input {
  width: 100%;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
}

.tilelft {
  padding-left: 20px;
}

.tieright {
  padding-right: 20px;
}

.stage {
  margin-left: 14%;
}

.serialNumber {
  position: absolute;
  top: 10px;
  margin-left: 50px;
  width: 100px;
}
</style>
